import { tw } from '@/utils/tw';

export default tw.theme({
  slots: {
    base: [
      'shape-sticker',
      'w-32',
      'h-32',
      'm-grid-1/2',
      'bg-red',
      'float-right',
      '-rotate-[9deg]',
      'not-prose',
      'md:w-40',
      'md:h-40',
    ],
    quote: [
      'm-0',
      'p-[15%]',
      'w-full',
      'h-full',
      'flex',
      'box-border',
      'justify-center',
      'items-center',
      'text-center',
      'text-quote-sm',
      'text-white',
      'hyphens-auto',

      'md:text-quote-md',
    ],
  },
});
