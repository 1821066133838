import { tw } from '@/utils/tw';
import BaseLoopAroundTheme from 'base/components/LoopAround/theme';

const LoopAround = tw.theme({
  extend: BaseLoopAroundTheme,
  variants: {
    variant: {
      primary: {
        base: ['bg-transparent', 'py-1.5'],
        reel: [
          'text-headline-sm',
          'uppercase',
          'not-italic',
          'text-gray-950',
          'group-[&:is(.bg-red,.bg-blue,.bg-black)]/box:text-white',

          '[&_span]:before:mx-1',
          '[&_span]:before:content-["•"]',
        ],
      },
      secondary: {
        base: [
          'flex',
          'flex-row',
          'flex-nowrap',
          'overflow-hidden',
          'whitespace-nowrap',
          'bg-transparent',
          'uppercase',
          'italic',
          'text-white',
          '[text-shadow:1px_2px_0_black]',
        ],
        reel: [
          'animate-loop',
          'uppercase',
          'italic',
          'text-headline-2xs',
          'container-col-sm-8:text-headline-sm',
          'md:text-headline-md',
          '[&_span]:before:mx-1',
          '[&_span]:before:content-["•"]',
        ],
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export default LoopAround;
