import { tw } from '@/utils/tw';
import BaseArticleInTextTeaserTheme from 'base/components/ArticleInTextTeaser/theme';

export default Object.assign(
  tw.theme({
    extend: BaseArticleInTextTeaserTheme,
    slots: {
      base: ['bg-yellow', 'rounded-lg', 'p-3', 'not-prose', 'gap-y-2', 'gap-x-3'],
      media: ['rounded-xl', 'overflow-hidden'],
    },
    variants: {
      orientation: {
        horizontal: {
          base: ['grid-cols-[3fr_5fr]'],
          media: ['md:grid-cols-7:row-span-auto', 'md:grid-cols-7:row-start-auto'],
          ticker: ['text-headline-3xs', 'uppercase', 'text-red', 'md:grid-cols-7:col-start-1'],
          title: [
            '!text-headline-xs', // override breakpoints
          ],
          kicker: [
            '!text-kicker-sm', // override breakpoints
          ],
          group: [
            '!m-0', // override breakpoints
          ],
        },
        vertical: {},
      },
    },
  }),
  {
    embed: {
      ...BaseArticleInTextTeaserTheme.embed,
    },
  },
);
