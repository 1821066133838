import { ArticleInTextTeaserTheme } from '@/components/ArticleInTextTeaser/theme';
import { VideoTheme } from '@/components/Video/theme';
import { tw } from '@/utils/tw';

export default Object.assign(
  tw.theme({
    extend: ArticleInTextTeaserTheme,
  }),
  {
    embed: {
      ...ArticleInTextTeaserTheme.embed,
      video: tw.theme({
        extend: VideoTheme,
        slots: {
          icon: ['left-2', 'bottom-2'],
        },
      }),
    },
  },
);
