import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const { Button } = BaseRowTheme;

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: ['max-w-content'],
    headline: ['text-headline-md sm:text-headline-lg'],
    caption: ['text-body-xs'],
  },
});

export default Object.assign(Row, { Button });
