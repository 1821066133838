import { Icon } from '@/components/Icon';
import { VideoInTextTeaser } from '@/components/VideoInTextTeaser';
import { VideoTeaser } from '@/components/VideoTeaser';
import { LabradorComponent } from '@/types/component';

export const HantLabradorJwVideoTeaser: LabradorComponent = ({ type, data, meta, descendants, ...props }) => {
  const {
    badgeData: badge,
    prominentTag,
    displayProminentTagOnTeaser,
    isFrontPage,
    videoID,
    thumbnail,
    teaserLink,
    title,
    hintText,
    stillImage,
  } = data;

  const { teaserIndex, path } = meta;

  const isPriority = teaserIndex < 4;
  const isInText = path?.includes?.('bodytext');

  const tickerText = badge.name ?? (displayProminentTagOnTeaser ? prominentTag : undefined);
  const ticker = isFrontPage ? (
    badge.name || displayProminentTagOnTeaser ? (
      <>
        {prominentTag}
        <Icon options={{ className: 'rotate-180 !h-4 !w-4' }} name="sliderArrow" />
      </>
    ) : undefined
  ) : (
    'Videos från Hänt'
  );

  const Component = isInText ? VideoInTextTeaser : VideoTeaser;

  return (
    <Component
      kicker={hintText}
      link={{
        teaser: {
          href: teaserLink,
        },
      }}
      tag={displayProminentTagOnTeaser ? prominentTag : undefined}
      ticker={ticker}
      title={title}
      video={{
        src: !stillImage ? `https://cdn.jwplayer.com/v2/media/${videoID}/poster.mp4?width=640` : undefined,
        poster: thumbnail,
        options: {
          priority: isPriority,
          preload: isPriority ? 'auto' : 'metadata',
        },
      }}
      data-ticker-text={tickerText}
      data-index={teaserIndex}
      {...props}
    />
  );
};
