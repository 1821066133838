import { tw } from '@/utils/tw';
import CheckboxTheme from 'base/components/Checkbox/theme';

const { Caption } = CheckboxTheme;

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: {
    base: [],
    label: ['text-gray-950', 'text-input-md'],
    input: [
      'p-0',
      'shadow-inset-1',
      'shadow-gray-600',

      'hover:bg-gray-50',
      'hover:shadow-gray-950',
      'hover:shadow-inset-1',

      'focus:bg-white',
      'focus:shadow-blue-900',
      'focus:shadow-inset-2',

      'active:bg-gray-200',
      'active:shadow-gray-950',
      'active:shadow-inset-1',
    ],
  },
});

export default Object.assign(Checkbox, { Caption });
